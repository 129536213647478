import { useEffect } from 'react'   // eslint-disable-line no-unused-vars
import { useState } from 'react'
import { Container, Form, InputGroup, Button } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Tippy from '@tippyjs/react'
import moment from 'moment'

import "react-datepicker/dist/react-datepicker.css";
import 'tippy.js/dist/tippy.css'; // optional


/**
 * "Instruments" search form, sends state back to parent
 * @param {} props 
 * @returns a search form 
 */
const SearchInstruments = (props) => {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [description, setDescription] = useState("");
    const [qtype, setqtype] = useState('ANY');

    const oldest = new Date('1900/1/1');
    const today = new Date();
    const [startDate, setStartDate] = useState(oldest);
    const [endDate, setEndDate] = useState(new Date());

    //console.log('search form')

    // yes debounce
    useEffect(() => {
        const getData = setTimeout(() => {
            const allitems = {
                "lastname": lastname, 
                "firstname": firstname, 
                "description": description,
                "querytype": qtype,
                "startdate": startDate,
                "enddate": endDate,
            }
            props.setinput(allitems); // send everything back to the parent <Instruments>
        }, 400)
        return () => clearTimeout(getData);
    }, [lastname, firstname, description]);
    
    // no debounce
    // but wait for complete date
    useEffect(() => {
        if (moment(startDate, 'MM/DD/YYYY', true).isValid()
         && moment(endDate, 'MM/DD/YYYY', true).isValid()) {
            const allitems = {
                "lastname": lastname, 
                "firstname": firstname, 
                "description": description,
                "querytype": qtype,
                "startdate": startDate,
                "enddate": endDate,
            }
            props.setinput(allitems); // send everything back to the parent <Instruments>
        }
    }, [startDate, endDate, qtype]);
    
    const resetForm = ((e) => {
        setLastname('');
        setFirstname('');
        setDescription('');
        setqtype('ANY');
        setStartDate(oldest);
        setEndDate(today);
    });
    
    const changeFirstname = ((e) => { setFirstname(e.target.value)})
    const changeLastname = ((e) => { 
        //console.log(e.target.value);
        setLastname(e.target.value) 
    })
    const changeDescription = ((e) => { setDescription(e.target.value)})
    const changeQuerytype = ((e) => { setqtype(e.target.value)})
    const queryHelp = 'This setting affects all the search fields. Hint: You can use % as a wildcard character. For example, try using EXACT, set last name to "Smith" and first name to "J%"';

    return (
        <Container>
            <Form>
                <InputGroup>
                    <InputGroup.Text>Last Name</InputGroup.Text>
                    <Tippy content="Hint, you can use a last name or a business or other entity name in this field.">
                    <Form.Control value={lastname} onChange={changeLastname}/>
                    </Tippy>
                    <InputGroup.Text>First Name</InputGroup.Text>
                    <Form.Control value={firstname} onChange={changeFirstname}/>
                </InputGroup>

                <InputGroup>
                    <InputGroup.Text>Description</InputGroup.Text>
                    <Tippy content='Hint, try typing "Deed" or "License" or recording or instrument description or instrument number.'>
                    <Form.Control 
                        value={description} 
                        onChange={changeDescription}
                        maxLength = {30}
                    />
                    </Tippy>
                </InputGroup>

                <InputGroup>
                    <InputGroup.Text>Query Type</InputGroup.Text>
                    <Tippy content={queryHelp}>
                        <Form.Select onChange={changeQuerytype}>
                            <option value="ANY">Any</option>
                            <option value="STARTSWITH">Starts with</option>
                            <option value="EXACT">Exact match</option>
                        </Form.Select>
                    </Tippy>
                </InputGroup>

                <InputGroup>
                    <InputGroup.Text>Search from</InputGroup.Text>
                    <DatePicker selected={startDate} 
                        onChange={(date) => setStartDate(date)} 
                        minDate={oldest}
                        maxDate={today}
                    />
                    <InputGroup.Text>to</InputGroup.Text>
                    <DatePicker selected={endDate} 
                        onChange={(date) => setEndDate(date)}
                        minDate={oldest}
                        maxDate={today} 
                    />
                </InputGroup>

                <Button variant="outline-secondary" onClick={resetForm}>Reset Form</Button>
                
            </Form>
       </Container>
    );
}

/* Not time for this yet

<Tippy content="Try the same search again.">
                <Button variant="outline-secondary" onClick={retrySearch} hidden={hideSearch}>Search</Button>
                </Tippy>

                */

                
export default SearchInstruments;
