import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
import { AppSettings } from './appSettings'

const apiServer = AppSettings.SERVER;

const client = new ApolloClient({
  uri: apiServer,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  <ApolloProvider client={client}>
    <App title="Clatsop County Records" />
  </ApolloProvider>
);
