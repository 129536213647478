import React from 'react'
import { useEffect, useState } from 'react';   // eslint-disable-line no-unused-vars
import { Form, InputGroup, Button, Container, Spinner } from 'react-bootstrap'
import { DataTable } from './datatable'
import { useQuery } from '@apollo/client'
import SearchInstruments from './search_instruments_form'
import { GET_INSTRUMENTS } from '../queries'
import "react-datepicker/dist/react-datepicker.css"
import 'tippy.js/dist/tippy.css' // optional

const InstrumentDetail = (props) => {
    //console.log("InstrumentDetail props=", JSON.stringify(props));
    let item = props.row;

    if (item === 0) return <></>

    // Show full date and time here
    const datetime = new Date(item.recording_date).toLocaleString()

    // Don't show number of pages unless there is a number
    // Test case: Frederick Le Clair (space in last name!)
    const numpages = (item.number_of_pages>0)? <> <b>{item.number_of_pages}</b> page{(item.number_of_pages==1)||'s'} &nbsp; </> : <></>
    // Don't show book, page unless there is something there
    // Test case: Weltzin
    // Test case: Canoe Club :-)
    const book = item.book? <>Book <b>{item.book}</b></> : <></>
    const page = (item.page>0)? <>Page <b>{item.page}</b></> : <></>
    
    // Don't show name if there is not one.
    const name = (item.firstname || item.middlename || item.lastname)
        ? <>Name: <b>{item.firstname} {item.middlename} {item.lastname}</b> <br /></>
        : <></>

    // Don't show "original" fields unless at least one has some data in it.
    // Test case: Brian Kirk 9/23/2002
    // Another: Rodney L Berlin
    // Test with instrument_id = 09712815 and 09810299 (amount>0)
    const orig_book = item.original_book? <>Orig Book <b>{item.original_book}</b></> : <></>
    const orig_page = item.original_page? <>Orig Page <b>{item.original_page}</b></> : <></>
    const orig_inst = item.original_instrument? <>Orig Inst # <b>{item.original_instrument}</b></> : <></>
    const orig = (item.original_book || item.original_page || item.original_instrument)
        ? <><br /></>
        : <></>

    const amount = (item.consideration_amount>0)
        ? <>Consideration Amount: <b>{item.consideration_amount}</b></> 
        : <></>

    return (
        <Container>
            <div className="instrument-detail">
                Instrument <b>{item.instrument_id}</b> &nbsp;
                Recording date: <b>{datetime}</b> <br />
                {name} 
                Party type <b>{item.party_type}</b> &nbsp; 
                {numpages} {book} {page}
                <br />
                Recording Description: <b>{item.recording_description}</b> <br />
                Instrument Description: <b>{item.instrument_description}</b> <br />
                {orig_book} {orig_page} {orig_inst} {orig}
                {amount}
            </div>
        </Container>
    );
}

const InstrumentList = (props) => {
    const [row, setRow] = useState(0);
    const { loading, error, data } = useQuery(GET_INSTRUMENTS, {variables: {...props.params}});
    if (error) {
        let gqlmsg = '';
        let emailmsg = 'mailto:gisinfo@clatsopcounty.gov?subject=Records%20app%20error';
        try {
            if (error.named === 'ApolloError') {
                gqlmsg = 'GQL server is offline.';
            } else {
                // There might be an additional code, or not.
                gqlmsg = error.graphQLErrors[0].extensions.code;
            }
        } catch (e) {
            gqlmsg = `Database server is offline. (${e.message})`;
        }
        emailmsg += '&body=' + encodeURI(error.message + '\n' + gqlmsg);
        return (
            <Container>
            <h4>Error: {error.message}</h4>
            <b>{gqlmsg}</b>
            <p>
                Please reload the page and try again. If the problem persists let us know about it.
                <br /><a href={emailmsg}>Send email</a>
            </p>
            </Container>
        );
    }
    if (loading) {
        return (
            <Container>
                <Spinner animation='border' role='status'>
                    <span className="visually-hidden">Searching...</span>
                </Spinner>
            </Container>
        );
    }

    if (!data || !data.instruments) {
        // This fires when the form is empty.
        return (
            <Container><i>Enter a search term in the form.</i></Container>
        )
    }

    const instruments = data.instruments;
    if (instruments.length == 0) {
        return (
            <Container><i>No matches found.</i></Container>
        )
    }

    return (
        <Container>
            <InstrumentDetail row={row}/>
            <DataTable list={instruments} selectedrow={setRow}/>
        </Container>
    );
}


   
/**
 * This is the main component of the app,
 * including the search form, results list, and detail.
 * @returns results of query operations
 */
const Instruments = () => {
    const [input,setInput] = useState({
        "lastname": '',
        "firstname": '',
        "description": '',
        "querytype": 'ANY',
        "startdate": '',
        "enddate": ''
    });
    return (
        <>
            <h3>Records Search</h3>
            <SearchInstruments setinput={setInput}/>
            <InstrumentList params={input}/>
        </>
    )
}
export default Instruments;

