import { gql,useQuery } from '@apollo/client';
import { PING } from '../queries';

/**
 * Pings the API server and returns the status. 
 */
const Ping = () => {
    const { loading, error, data } = useQuery(PING);
    if (loading) {
        return (
            <font color="blue">Loading</font>
        )
    }
    if (error) {
        return (
            <font color="red">Not available</font>
        );
    }
    return (
        <font color="green">Ready {data.ping.timestamp} {data.ping.version}</font>
    );
}
export default Ping