/**
 * Display the contents of a JSON object as a table,
 * currently this means display Instruments
 * since that's all I currently need to do!
 */
import React from 'react'; // eslint-disable-line no-unused-vars
import { useState, useEffect, useCallback, useMemo } from 'react'
import { DataGrid } from '@mui/x-data-grid'

// DataGrid docs are here, https://mui.com/x/react-data-grid/
// This MUI datagrid is currently the free (MIT) licensed version.
// the commercial version has more features but the MIT version is good too.
// NB it does its own tooltip thing so Tippy does not work here.

// This one worked well too https://github.com/adazzle/react-data-grid/blob/main/README.md

    
const createColumns = () => {
    return [
        {field: 'id', headerName: 'Row#', width:75, filterable:false,
            valueGetter: (params)=> {
                // Force it to be a number which helps with sorting.
                const d = Number(params.value);
                //console.log(params.value,'=>',d);
                return d;
            }},
        {field: 'instrument_id', headerName: 'Instrument', width:100},
        {field: 'firstname', headerName: 'First name', width:150},
        {field: 'middlename', headerName: 'MI', width:50},       
        {field: 'lastname', headerName: 'Last name', width: 200},
        {field: 'recording_date', headerName: 'Recording date',  
            valueGetter: (params)=> {
                // Timestamp comes over GraphQL as a number
                // Only show the date here, no time
                const d = new Date(params.value).toLocaleDateString();
                //console.log(params.value,'=>',d);
                return d;
            }},
        {field: 'book', headerName: 'Book', width: 50},
        {field: 'page', headerName: 'Page', width: 50},
        {field: 'instrument_description', headerName: 'Instrument Type', width:300},
        ];
}

export const DataTable = (props) => {
    const [rows, setData] = useState(props.list)
    const [columns, setColumns] = useState(createColumns);

    const [help, setHelp] = useState("Click on a row to see the details.");
   
    const handleRowClick = (e) => {
        //console.log('click!', JSON.stringify(e));
        props.selectedrow(e.row); // push new row to parent
        setHelp('');
    }

    useEffect(() => {
        if (rows.length == 1) {
            // there is just one row, select it.
            props.selectedrow(rows[0]); // push new row to parent
            setHelp('');
        } else {
            const MAXROWS = 250;
            if (rows.length >= MAXROWS) {
                setHelp(`Search resulted in more than ${MAXROWS} matches. Only ${MAXROWS} are shown; refine your search.`)
            }
            // clear any existing selection
            props.selectedrow(0);
        }
    }, rows);

    return (
        <>
            <i>{help}</i>
            <DataGrid 
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize:10, page:0
                        },
                    } 
                }}
                pageSizeOptions={[10,25,100]}
                onRowClick={handleRowClick}
                getRowId={(row) => row.id}
                columns={columns} 
                rows={rows}
                disableMultipleRowSelection={true}
            />
        </>
    )
}
